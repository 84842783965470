import * as Sentry from "@sentry/react"
import { CaptureContext, ScopeContext } from "@sentry/types"
import { last } from "../commercetools/Utils"
import { useAppStore } from "../State"
import { ifBrowser } from "../utils/Browser"
import { createLogger } from "../utils/createLogger"

const logger = createLogger("Sentry")

export const useSentryLogging = () => {
  const store = useAppStore()
  const captureMessage = (
    message: string,
    captureContext?: Partial<ScopeContext>
  ) => {
    ifBrowser(() => {
      const token = store.getState().accessTokenState.token

      const context: CaptureContext = {
        ...(captureContext || {}),
        extra: {
          ...(captureContext?.extra || {}),
          url: document?.URL,
          accessTokenSubstring: last(8, token?.access_token),
          refreshTokenSubstring: last(8, token?.refresh_token),
          expiresAt: token?.expires_at
            ? new Date(token?.expires_at)
            : undefined,
          time: new Date()
        }
      }

      logger.info(message, captureContext)
      Sentry.captureMessage(message, context)
    })
  }

  return {
    captureMessage
  }
}
